import React, { ReactElement, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MarketButton,
  MarketEmptyState,
  MarketLink,
  MarketTooltip,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import { M_PLUS_SUBSCRIPTION_FAQ_URL, SUPPORT_URL } from 'src/utils/url';
import { getReferralPageName } from 'src/utils/transcriptUtils';
import './ProhibitedState.scss';
import 'src/components/UpgradeEmptyState/UpgradeEmptyState.scss';
import ChatBubblesIcon from 'src/svgs/ChatBubblesIcon';
import classNames from 'classnames';

/**
 * Component that renders a message in place of the input bar describing that the user is unable to
 * subscribe to M+ due to the nature of their business.
 */
const ProhibitedState = observer((): ReactElement => {
  const { event, navigation, transcriptView, subscription } =
    useMessengerControllerContext();
  const { t } = useTranslation();
  const stackButtons = !navigation.secondary.isOpen;

  useEffect(() => {
    event.track('View CBD Null State', {
      referral_page_name: 'conversation',
    });
  }, [event]);

  if (subscription.isEligibleForSquareOne) {
    return (
      <MarketEmptyState
        className="ProhibitedState"
        data-testid="ProhibitedState"
      >
        <h3 className="heading-20" slot="primary-text">
          <div className="UpgradeEmptyState__icon">
            <ChatBubblesIcon />
          </div>
          {t('ProhibitedState.title')}
        </h3>
        <p className="paragraph-30" slot="secondary-text">
          {t('ProhibitedState.description')}
        </p>
        <div slot="actions" className="UpgradeEmptyState__actions-container">
          <div
            className={classNames(
              'UpgradeEmptyState__actions-container__buttons',
              {
                'UpgradeEmptyState__actions-container__buttons__stack':
                  stackButtons,
              },
            )}
          >
            {subscription.isMessagesFeatureEnabledWithSquareOne && (
              <MarketButton rank="secondary" disabled>
                {t('ProhibitedState.claim_number_button')}
              </MarketButton>
            )}
            <MarketButton rank="primary" href={SUPPORT_URL} target="_blank">
              {t('ProhibitedState.support_button')}
            </MarketButton>
          </div>

          <MarketTooltip interaction="persistent" popoverPlacement="bottom">
            <span slot="trigger">{t('ProhibitedState.tooltip')}</span>
            <div slot="content" className="UpgradeEmptyState__tooltip-content">
              <p className="UpgradeEmptyState__tooltip-text">
                {t('ProhibitedState.tooltip_popover')}
              </p>
              <MarketLink
                href={M_PLUS_SUBSCRIPTION_FAQ_URL}
                target="_blank"
                className="UpgradeEmptyState__tooltip__link"
                onClick={() => {
                  event.track('Click CBD Learn More', {
                    referral_page_name: getReferralPageName({
                      isTranscriptView:
                        navigation.navStoreForUrl.currentPageName ===
                        'TRANSCRIPT_VIEW',
                      isEmpty: transcriptView.transcript.viewItems.length === 0,
                      isActive: transcriptView.transcript.isActive,
                    }),
                  });
                }}
              >
                <span className="UpgradeEmptyState__tooltip-cta">
                  {t('common.learn_more')}
                </span>
              </MarketLink>
            </div>
          </MarketTooltip>
        </div>
      </MarketEmptyState>
    );
  }

  return (
    <MarketEmptyState className="ProhibitedState" data-testid="ProhibitedState">
      <h3 className="heading-30" slot="primary-text">
        {t('MessagesPlus.empty_state.prohibited.title')}
      </h3>
      <p className="paragraph-30" slot="secondary-text">
        <Trans
          i18nKey="MessagesPlus.empty_state.prohibited.description"
          components={{
            1: (
              <MarketLink href={M_PLUS_SUBSCRIPTION_FAQ_URL} target="_blank" />
            ),
          }}
        />
      </p>
      <MarketButton
        rank="primary"
        slot="actions"
        href={M_PLUS_SUBSCRIPTION_FAQ_URL}
        target="_blank"
        onClick={() => {
          event.track('Click CBD Learn More', {
            referral_page_name: getReferralPageName({
              isTranscriptView:
                navigation.navStoreForUrl.currentPageName === 'TRANSCRIPT_VIEW',
              isEmpty: transcriptView.transcript.viewItems.length === 0,
              isActive: transcriptView.transcript.isActive,
            }),
          });
        }}
      >
        {t('common.learn_more')}
      </MarketButton>
    </MarketEmptyState>
  );
});

export default ProhibitedState;
