import { observer } from 'mobx-react';
import React, { createRef, ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  MarketBanner,
  MarketButton,
  MarketLink,
  MarketList,
  MarketRow,
  MarketToggle,
} from 'src/components/Market';
import { useMessengerControllerContext } from 'src/context/MessengerControllerContext';
import {
  GEN_AI_TERMS_URL,
  getTeamPermissionsUrl,
  M_PLUS_PRIVACY_POLICY_URL,
} from 'src/utils/url';
import {
  KEY_MESSAGES_PLUGIN_CODE_SNIPPET,
  KEY_MESSAGES_PLUS,
  KEY_REQUEST_GOOGLE_REVIEW,
} from 'src/stores/FeatureFlagStore';
import {
  MessengerModalFullContent,
  MessengerModalFullHeader,
} from 'src/components/MessengerModalFull';
import DoNotDisturbModal from './components/DoNotDisturbModal/DoNotDisturbModal';
import UnitSettings from './components/UnitSettings/UnitSettings';
import UnsavedModal from 'src/components/UnsavedModal/UnsavedModal';
import PluginSetting from './components/PluginSetting/PluginSetting';
import BusinessNumbersSetting from './components/BusinessNumbersSetting/BusinessNumbersSetting';
import SubscriptionSetting from './components/SubscriptionSetting/SubscriptionSetting';
import InGraceSubscriptionBanner from 'src/components/InGraceSubscriptionBanner/InGraceSubscriptionBanner';
import DelinquentSubscriptionBanner from 'src/components/DelinquentSubscriptionBanner/DelinquentSubscriptionBanner';
import MessagesPlusExpiringBanner from 'src/components/MessagesPlusExpiringBanner/MessagesPlusExpiringBanner';
import MessagesPluginSettings from './components/MessagesPluginSettings/MessagesPluginSettings';

/**
 * The page that contains messenger's settings. This is a different kind of page
 * from the rest as this lives inside a sheet instead of the drawer.
 */
const SettingsPage = observer((): ReactElement => {
  const {
    navigation,
    settings,
    status,
    modal,
    event,
    featureFlag,
    subscription,
    tooltip,
  } = useMessengerControllerContext();
  const {
    emailNotification,
    pushNotification,
    squareAssistant,
    doNotDisturb,
    hasUnitSettings,
    save,
    close,
    isDoNotDisturbEnabled,
    userMadeAChange,
    userEmailNotification,
    setUserEmailNotification,
    userPushNotification,
    setUserPushNotification,
    userSquareAssistant,
    setUserSquareAssistant,
    setUserDoNotDisturb,
    exitWithoutSaving,
    isMessagesPluginSettingsShown,
  } = settings;
  const { t } = useTranslation();

  const modalContentRef = createRef<HTMLElement>();

  useEffect(() => {
    if (settings.status === 'NOT_STARTED') {
      settings.init();
    } else if (settings.status === 'ERROR') {
      status.set({
        action: { action: () => settings.init() },
        actionLabel: t('StatusScreen.retry'),
        display: 'SCREEN',
        label: t('SettingsPage.failed.fetch'),
        type: 'ERROR',
        scope: 'SHEET',
      });
      // Clean up function to clear status if we navigate away from settings page
      return () => {
        status.clear();
      };
    }
    return undefined;
  }, [status, t, settings.status, settings]);

  useEffect(() => {
    event.track('View Settings');
  }, [event]);

  /**
   * Saving
   */
  const [isSaving, setIsSaving] = useState(false);
  const saveSettings = (): void => {
    setIsSaving(true);
    save()
      .then(() => {
        status.setSuccess({
          label: t('SettingsPage.success'),
        });
        navigation.closeSheet();
        settings.clearUserSettings();
      })
      .catch(() => {
        status.setError({
          label: t('SettingsPage.failed.update'),
          display: 'BANNER',
          scope: 'SHEET',
        });
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  /**
   * Notifications
   */
  let notificationComponent;
  if (
    emailNotification.appliesToCurrentEmployee ||
    pushNotification.appliesToCurrentEmployee
  ) {
    notificationComponent = (
      <>
        <h3>{t('SettingsPage.notifications.title')}</h3>
        <MarketList multiselect>
          {emailNotification.appliesToCurrentEmployee && (
            <MarketRow
              selected={
                (userEmailNotification ?? emailNotification.boolValue) ||
                undefined
              }
              onMarketRowSelected={() => setUserEmailNotification(true)}
              onMarketRowDeselected={() => setUserEmailNotification(false)}
              data-testid="SettingsPage__notifications__email"
            >
              <label slot="label">
                {t('SettingsPage.notifications.email')}
              </label>
              <MarketToggle slot="control" />
            </MarketRow>
          )}
          {pushNotification.appliesToCurrentEmployee && (
            <MarketRow
              selected={
                (userPushNotification ?? pushNotification.boolValue) ||
                undefined
              }
              onMarketRowSelected={() => setUserPushNotification(true)}
              onMarketRowDeselected={() => setUserPushNotification(false)}
              data-testid="SettingsPage__notifications__push"
            >
              <label slot="label">{t('SettingsPage.notifications.push')}</label>
              <MarketToggle slot="control" />
            </MarketRow>
          )}
        </MarketList>
      </>
    );
  }

  /**
   * Square Assistant
   */
  let squareAssistantComponent;
  if (squareAssistant.appliesToCurrentEmployee) {
    squareAssistantComponent = (
      <MarketList multiselect>
        <h3>{t('SettingsPage.assistant.title')}</h3>
        <MarketRow
          selected={
            (userSquareAssistant ?? squareAssistant.boolValue) || undefined
          }
          onMarketRowSelected={() => setUserSquareAssistant(true)}
          onMarketRowDeselected={() => setUserSquareAssistant(false)}
          data-testid="SettingsPage__square-assistant"
        >
          <label slot="label">{t('SettingsPage.assistant.label')}</label>
          <p slot="subtext" data-testid="SettingsPage__assistant__description">
            <Trans
              i18nKey="SettingsPage.assistant.description"
              components={{
                1: <MarketLink href={GEN_AI_TERMS_URL} target="_blank" />,
                2: (
                  <MarketLink
                    href={M_PLUS_PRIVACY_POLICY_URL}
                    target="_blank"
                  />
                ),
              }}
            />
          </p>
          <MarketToggle slot="control" />
        </MarketRow>
      </MarketList>
    );
  }

  /**
   * Do not disturb
   */
  let doNotDisturbComponent;
  if (doNotDisturb.appliesToCurrentEmployee) {
    doNotDisturbComponent = (
      <>
        <h3>{t('SettingsPage.do_not_disturb.title')}</h3>
        <MarketRow
          selected={isDoNotDisturbEnabled || undefined}
          onMarketRowSelected={() => {
            modal.openDoNotDisturbModal();
            event.track('Click Do Not Disturb Toggle', {
              is_dnd_enabled: true,
            });
          }}
          onMarketRowDeselected={() => {
            setUserDoNotDisturb(false);
            event.track('Click Do Not Disturb Toggle', {
              is_dnd_enabled: false,
            });
          }}
          data-testid="SettingsPage__dnd"
        >
          <label slot="label">{t('SettingsPage.do_not_disturb.label')}</label>
          <p slot="subtext">{t('SettingsPage.do_not_disturb.description')}</p>
          <MarketToggle slot="control" checked={isDoNotDisturbEnabled} />
        </MarketRow>
      </>
    );
  }

  /**
   * Permissions
   */
  let permissionsComponent;
  if (
    emailNotification.appliesToCurrentEmployee &&
    squareAssistant.appliesToCurrentEmployee
  ) {
    permissionsComponent = (
      <>
        <h3>{t('SettingsPage.permissions.title')}</h3>
        <p
          className="paragraph-30"
          data-testid="SettingsPage__permissions-description"
        >
          <Trans
            i18nKey="SettingsPage.permissions.description"
            components={{
              1: <MarketLink href={getTeamPermissionsUrl} target="_blank" />,
            }}
          />
        </p>
      </>
    );
  }

  // Show business numbers section if user already started signing up or is successfully subscribed
  const showBusinessNumbers =
    featureFlag.get(KEY_MESSAGES_PLUS) &&
    (subscription.isSubscribed || subscription.hasPendingDedicatedNumber) &&
    !subscription.isProhibited;

  // Show subscription section if user is eligible for M+, not in pending verification period, and not eligible for Square One
  const showSubscription =
    featureFlag.get(KEY_MESSAGES_PLUS) &&
    !subscription.isEligibleForSquareOne &&
    (subscription.isSubscribed || !subscription.hasPendingDedicatedNumber) &&
    !subscription.isProhibited;

  return (
    <>
      <MessengerModalFullHeader
        title={t('SettingsPage.title')}
        onNavigateClick={close}
        primaryButton={
          <MarketButton
            rank="primary"
            onClick={saveSettings}
            isLoading={isSaving || undefined}
            disabled={!userMadeAChange || undefined}
          >
            {t('SettingsPage.save')}
          </MarketButton>
        }
      />
      <MessengerModalFullContent
        status={status.value}
        isLoading={
          settings.status === 'NOT_STARTED' || settings.status === 'LOADING'
        }
        ref={modalContentRef}
      >
        {isDoNotDisturbEnabled ? (
          <MarketBanner variant="info">
            <span slot="title">
              {t('SettingsPage.do_not_disturb_banner.title')}
            </span>
            {t('SettingsPage.do_not_disturb_banner.description')}
            <button
              slot="action"
              onClick={() => {
                setUserDoNotDisturb(false);
              }}
            >
              {t('SettingsPage.do_not_disturb_banner.turn_off_cta_label')}
            </button>
          </MarketBanner>
        ) : (
          <>
            {featureFlag.get(KEY_MESSAGES_PLUS) &&
              !subscription.isEligibleForSquareOne &&
              subscription.inGraceWithActiveSubscription &&
              tooltip.isVisible('SUBSCRIPTION_IN_GRACE_BANNER') && (
                <InGraceSubscriptionBanner
                  onClick={() => {
                    event.track('Click Update Payment', {
                      referral_page_name: 'messages_settings',
                    });
                  }}
                />
              )}
            {featureFlag.get(KEY_MESSAGES_PLUS) &&
              !subscription.isEligibleForSquareOne &&
              subscription.isSubscriptionDelinquent &&
              tooltip.isVisible('SUBSCRIPTION_DELINQUENT_BANNER') && (
                <DelinquentSubscriptionBanner
                  onClick={() => {
                    event.track('Click Resubscribe', {
                      referral_page_name: 'messages_settings',
                    });
                  }}
                />
              )}
            <MessagesPlusExpiringBanner />
            {showBusinessNumbers && <BusinessNumbersSetting />}
            {notificationComponent}
            {squareAssistantComponent}
            {isMessagesPluginSettingsShown && <MessagesPluginSettings />}
            {featureFlag.get(KEY_MESSAGES_PLUGIN_CODE_SNIPPET) && (
              <PluginSetting />
            )}
            {featureFlag.get(KEY_REQUEST_GOOGLE_REVIEW) && hasUnitSettings && (
              <UnitSettings />
            )}
            {doNotDisturbComponent}
            {permissionsComponent}
            {showSubscription && <SubscriptionSetting />}
          </>
        )}
        {modal.currentModal === 'DO_NOT_DISTURB' && (
          <DoNotDisturbModal
            onConfirm={() => {
              if (modalContentRef.current) {
                // Workaround for market handling that adds a padding top on scroll
                // https://github.com/squareup/market/blob/ba372133f3f9095c28bff90c52c65cb8b74474ca/web/web-components/src/utils/dialog.tsx#L60-L84
                modalContentRef.current.style.paddingTop = '0';
              }
            }}
          />
        )}
        {modal.currentModal === 'UNSAVED' && (
          <UnsavedModal onConfirm={exitWithoutSaving} />
        )}
      </MessengerModalFullContent>
    </>
  );
});

export default SettingsPage;
